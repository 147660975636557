import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { IExpandableObject } from '../expandable-object';
import { IDonorSampleLabData } from '../interfaces/donor-sample-lab-data';

export interface IDonorSampleLabDataDynamicControlsParameters {
    formGroup?: string;
}

export class DonorSampleLabDataDynamicControls {

    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private donorsamplelabdata?: IDonorSampleLabData, additionalParameters?: IDonorSampleLabDataDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'DonorSampleLabData';

        this.Form = {
            Can: new DynamicField({
                formGroup: this.formGroup,
                label: 'Can',
                name: 'Can',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsamplelabdata && this.donorsamplelabdata.Can || null,
            }),
            Cane: new DynamicField({
                formGroup: this.formGroup,
                label: 'Cane',
                name: 'Cane',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsamplelabdata && this.donorsamplelabdata.Cane || null,
            }),
            CountTech: new DynamicField({
                formGroup: this.formGroup,
                label: 'Count Tech',
                name: 'CountTech',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(10) ],
                validators: { 'maxlength': 10 },
                value: this.donorsamplelabdata && this.donorsamplelabdata.hasOwnProperty('CountTech') && this.donorsamplelabdata.CountTech !== null ? this.donorsamplelabdata.CountTech.toString() : '',
            }),
            LabelVerifiedBy: new DynamicField({
                formGroup: this.formGroup,
                label: 'Label Verified By',
                name: 'LabelVerifiedBy',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(10) ],
                validators: { 'maxlength': 10 },
                value: this.donorsamplelabdata && this.donorsamplelabdata.hasOwnProperty('LabelVerifiedBy') && this.donorsamplelabdata.LabelVerifiedBy !== null ? this.donorsamplelabdata.LabelVerifiedBy.toString() : '',
            }),
            Motility: new DynamicField({
                formGroup: this.formGroup,
                label: 'Motility (%)',
                name: 'Motility',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.min(0), Validators.max(99) ],
                validators: { 'min': 0, 'max': 99 },
                value: this.donorsamplelabdata && this.donorsamplelabdata.Motility || null,
            }),
            NonMotileConcentration: new DynamicField({
                formGroup: this.formGroup,
                label: 'Non Motile Concentration',
                name: 'NonMotileConcentration',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsamplelabdata && this.donorsamplelabdata.NonMotileConcentration || null,
            }),
            NumberOfVials: new DynamicField({
                formGroup: this.formGroup,
                label: 'Number Of Vials',
                name: 'NumberOfVials',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsamplelabdata && this.donorsamplelabdata.NumberOfVials || null,
            }),
            PostGradientDilutionFactor: new DynamicField({
                formGroup: this.formGroup,
                label: 'Post Gradient Dilution Factor',
                name: 'PostGradientDilutionFactor',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsamplelabdata && this.donorsamplelabdata.PostGradientDilutionFactor || null,
            }),
            PostGradientMotileConcentration: new DynamicField({
                formGroup: this.formGroup,
                label: 'Post Gradient Motile Concentration',
                name: 'PostGradientMotileConcentration',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsamplelabdata && this.donorsamplelabdata.PostGradientMotileConcentration || null,
            }),
            PostGradientVolume: new DynamicField({
                formGroup: this.formGroup,
                label: 'Post Gradient Volume',
                name: 'PostGradientVolume',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 3,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsamplelabdata && this.donorsamplelabdata.PostGradientVolume || null,
            }),
            ProcessingTech: new DynamicField({
                formGroup: this.formGroup,
                label: 'Processing Tech',
                name: 'ProcessingTech',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(10) ],
                validators: { 'maxlength': 10 },
                value: this.donorsamplelabdata && this.donorsamplelabdata.hasOwnProperty('ProcessingTech') && this.donorsamplelabdata.ProcessingTech !== null ? this.donorsamplelabdata.ProcessingTech.toString() : '',
            }),
            RawSemenDilutionFactor: new DynamicField({
                formGroup: this.formGroup,
                label: 'Raw Semen Dilution Factor',
                name: 'RawSemenDilutionFactor',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsamplelabdata && this.donorsamplelabdata.RawSemenDilutionFactor || null,
            }),
            RawSemenMotileConcentration: new DynamicField({
                formGroup: this.formGroup,
                label: 'Raw Semen Motile Concentration',
                name: 'RawSemenMotileConcentration',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsamplelabdata && this.donorsamplelabdata.RawSemenMotileConcentration || null,
            }),
            RawSemenVolume: new DynamicField({
                formGroup: this.formGroup,
                label: 'Raw Semen Volume',
                name: 'RawSemenVolume',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsamplelabdata && this.donorsamplelabdata.RawSemenVolume || null,
            }),
            RemainderVials: new DynamicField({
                formGroup: this.formGroup,
                label: 'Remainder Vials',
                name: 'RemainderVials',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsamplelabdata && this.donorsamplelabdata.hasOwnProperty('RemainderVials') && this.donorsamplelabdata.RemainderVials !== null ? this.donorsamplelabdata.RemainderVials : false,
            }),
            RoundCells: new DynamicField({
                formGroup: this.formGroup,
                label: 'Round Cells',
                name: 'RoundCells',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsamplelabdata && this.donorsamplelabdata.RoundCells || null,
            }),
            Tank: new DynamicField({
                formGroup: this.formGroup,
                label: 'Tank',
                name: 'Tank',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsamplelabdata && this.donorsamplelabdata.Tank || null,
            }),
            TestThawMotileConcentration: new DynamicField({
                formGroup: this.formGroup,
                label: 'Test Thaw Motile Concentration',
                name: 'TestThawMotileConcentration',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsamplelabdata && this.donorsamplelabdata.TestThawMotileConcentration || null,
            }),
            TestThawTech: new DynamicField({
                formGroup: this.formGroup,
                label: 'Test Thaw Tech',
                name: 'TestThawTech',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(10) ],
                validators: { 'maxlength': 10 },
                value: this.donorsamplelabdata && this.donorsamplelabdata.hasOwnProperty('TestThawTech') && this.donorsamplelabdata.TestThawTech !== null ? this.donorsamplelabdata.TestThawTech.toString() : '',
            }),
            Viscosity: new DynamicField({
                formGroup: this.formGroup,
                label: 'Viscosity',
                name: 'Viscosity',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsamplelabdata && this.donorsamplelabdata.Viscosity || null,
            }),
        };

        this.View = {
            Can: new DynamicLabel(
                'Can',
                this.donorsamplelabdata && this.donorsamplelabdata.Can || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            Cane: new DynamicLabel(
                'Cane',
                this.donorsamplelabdata && this.donorsamplelabdata.Cane || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            CountTech: new DynamicLabel(
                'Count Tech',
                this.donorsamplelabdata && this.donorsamplelabdata.hasOwnProperty('CountTech') && this.donorsamplelabdata.CountTech !== null ? this.donorsamplelabdata.CountTech.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            LabelVerifiedBy: new DynamicLabel(
                'Label Verified By',
                this.donorsamplelabdata && this.donorsamplelabdata.hasOwnProperty('LabelVerifiedBy') && this.donorsamplelabdata.LabelVerifiedBy !== null ? this.donorsamplelabdata.LabelVerifiedBy.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Motility: new DynamicLabel(
                'Motility (%)',
                this.donorsamplelabdata && this.donorsamplelabdata.Motility || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale:null,
                }),
            ),
            NonMotileConcentration: new DynamicLabel(
                'Non Motile Concentration',
                this.donorsamplelabdata && this.donorsamplelabdata.NonMotileConcentration || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
            ),
            NumberOfVials: new DynamicLabel(
                'Number Of Vials',
                this.donorsamplelabdata && this.donorsamplelabdata.NumberOfVials || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            PostGradientDilutionFactor: new DynamicLabel(
                'Post Gradient Dilution Factor',
                this.donorsamplelabdata && this.donorsamplelabdata.PostGradientDilutionFactor || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            PostGradientMotileConcentration: new DynamicLabel(
                'Post Gradient Motile Concentration',
                this.donorsamplelabdata && this.donorsamplelabdata.PostGradientMotileConcentration || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
            ),
            PostGradientVolume: new DynamicLabel(
                'Post Gradient Volume',
                this.donorsamplelabdata && this.donorsamplelabdata.PostGradientVolume || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 3,
                }),
            ),
            ProcessingTech: new DynamicLabel(
                'Processing Tech',
                this.donorsamplelabdata && this.donorsamplelabdata.hasOwnProperty('ProcessingTech') && this.donorsamplelabdata.ProcessingTech !== null ? this.donorsamplelabdata.ProcessingTech.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            RawSemenDilutionFactor: new DynamicLabel(
                'Raw Semen Dilution Factor',
                this.donorsamplelabdata && this.donorsamplelabdata.RawSemenDilutionFactor || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            RawSemenMotileConcentration: new DynamicLabel(
                'Raw Semen Motile Concentration',
                this.donorsamplelabdata && this.donorsamplelabdata.RawSemenMotileConcentration || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
            ),
            RawSemenVolume: new DynamicLabel(
                'Raw Semen Volume',
                this.donorsamplelabdata && this.donorsamplelabdata.RawSemenVolume || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
            ),
            RemainderVials: new DynamicLabel(
                'Remainder Vials',
                this.donorsamplelabdata && this.donorsamplelabdata.hasOwnProperty('RemainderVials') && this.donorsamplelabdata.RemainderVials !== null ? this.donorsamplelabdata.RemainderVials : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            RoundCells: new DynamicLabel(
                'Round Cells',
                this.donorsamplelabdata && this.donorsamplelabdata.RoundCells || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
            ),
            Tank: new DynamicLabel(
                'Tank',
                this.donorsamplelabdata && this.donorsamplelabdata.Tank || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            TestThawMotileConcentration: new DynamicLabel(
                'Test Thaw Motile Concentration',
                this.donorsamplelabdata && this.donorsamplelabdata.TestThawMotileConcentration || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
            ),
            TestThawTech: new DynamicLabel(
                'Test Thaw Tech',
                this.donorsamplelabdata && this.donorsamplelabdata.hasOwnProperty('TestThawTech') && this.donorsamplelabdata.TestThawTech !== null ? this.donorsamplelabdata.TestThawTech.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Viscosity: new DynamicLabel(
                'Viscosity',
                this.donorsamplelabdata && this.donorsamplelabdata.Viscosity || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
        };

    }
}
