import { LowerCasePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IClinicalParameterSettings } from '@model/interfaces/clinical-parameters-settings';
import { IDonorSampleLabData } from '@model/interfaces/donor-sample-lab-data';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root',
})
export class ClinicalParametersService {
    constructor(public http: HttpClient) { }

    _clinicalParameters?: IClinicalParameterSettings;

    loadClinicalParameters(): Observable<IClinicalParameterSettings> {
        return this.http.get<IClinicalParameterSettings>('/api/v1/clinicalparameters/get');
    }

    loadClinicalParameters2(): void {
        this.http.get<IClinicalParameterSettings>('/api/v1/clinicalparameters/get')
            .subscribe(results => {
                this._clinicalParameters = results;
            }
            );
    }

    whatAreThey(): IClinicalParameterSettings {
        return this._clinicalParameters;
    }

    //
    // Check the sample motility against clinical threshold for low motility
    //
    // MOTE: this._clinicalParameters must first be initialized by calliing loadClinicalParameters()
    //
    isMotilityLow(testThawLabData?: IDonorSampleLabData, clinicalParameters?: IClinicalParameterSettings): boolean {
        let lowMotility = false;
        
        if (testThawLabData && testThawLabData.Motility && clinicalParameters) {
            lowMotility = (testThawLabData.Motility < clinicalParameters.LowMotilityLevel);
        }

        return lowMotility;
    }
}
